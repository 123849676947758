import React from 'react';
import { resolveId } from '@wix/communities-blog-client-common';
import { CommentCountCompact } from '@app/external/comments/components/comment-count-compact';
import { LikeButtonWithCount } from '@app/external/common/components/like-button-with-count';
import PostIconRow from '@app/external/common/components/post-icon-row';
import PostSocialActions from '@app/external/common/components/post-social-actions';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import { HorizontalSeparator } from '@app/external/common/components/separator';
import ViewCountCompact from '@app/external/common/components/view-count-compact';
import {
  getCommentCount,
  getViewCount,
} from '@app/external/common/store/post-counters/post-counters-selectors';
import type { NormalizedPost } from '@app/external/common/types';
import { usePostPageShareProviders } from '../../hooks/use-post-page-share-providers';
import { getIsMetadataLoaded } from '../../store/is-loaded/is-loaded-selectors';
import { useSettingsContext } from '../post/settings-context';
import { SkeletonRenderer } from '../skeleton-renderer';
import styles from './post-main-actions-mobile.scss';

const PostMainActionsMobile: React.FC<{ post: NormalizedPost }> = ({ post }) => {
  const { showLikeCount, showCommentCount, showViewCount, isMetadataFooterVisible } =
    useSettingsContext();
  const { providers } = usePostPageShareProviders();

  const postId = resolveId(post);

  const actions = useActions();

  const isMetadataLoaded = useSelector(getIsMetadataLoaded);

  React.useEffect(() => {
    if (post.slug && !isMetadataLoaded) {
      actions.fetchPostMetadata(post.slug);
    }
  }, [actions, isMetadataLoaded, post.slug]);

  const viewCount = useSelector((state) => getViewCount(state, postId));
  const totalComments = useSelector((state) => getCommentCount(state, postId));

  return (
    <div className={styles.container}>
      {providers.length > 0 ? (
        <PostIconRow className={styles.shareButtons} postId={post.id!}>
          <PostSocialActions
            postSlug={post.slug}
            path={`/${post.slug}`}
            postId={resolveId(post)}
            enabledProviders={providers}
          />
        </PostIconRow>
      ) : null}
      <HorizontalSeparator />
      {isMetadataFooterVisible ? (
        <div className={styles.flexContainer}>
          <div className={styles.stats}>
            {showViewCount ? (
              <SkeletonRenderer isLoaded={isMetadataLoaded} width={20}>
                <ViewCountCompact count={viewCount} />
              </SkeletonRenderer>
            ) : null}
            {showCommentCount ? (
              <SkeletonRenderer isLoaded={isMetadataLoaded} width={20}>
                <CommentCountCompact count={totalComments} showZero={false} />
              </SkeletonRenderer>
            ) : null}
          </div>
          {showLikeCount ? (
            <SkeletonRenderer isLoaded={isMetadataLoaded} width={20}>
              <LikeButtonWithCount key={postId} postId={postId!} />
            </SkeletonRenderer>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default PostMainActionsMobile;
