import React from 'react';
import classNames from 'classnames';
import { resolveId } from '@wix/communities-blog-client-common';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import { usePostPageShareProviders } from '../../hooks/use-post-page-share-providers';
import { getFeedColorClassName } from '../../services/layout-config';
import { getIsMetadataLoaded } from '../../store/is-loaded/is-loaded-selectors';
import { getCommentCount, getViewCount } from '../../store/post-counters/post-counters-selectors';
import CategoryLabelList from '../category-label-list';
import { Counter } from '../counter';
import { LikeButtonWithCount } from '../like-button-with-count';
import PostLink from '../link/post-link';
import { useSettingsContext } from '../post/settings-context';
import PostIconRow from '../post-icon-row';
import PostPrintAction from '../post-print-action';
import PostSocialActions from '../post-social-actions';
import { useActions, useSelector } from '../runtime-context';
import { HorizontalSeparator } from '../separator';
import { SkeletonRenderer } from '../skeleton-renderer';
import ViewCount from '../view-count';
import type { Props } from './types';
import styles from './post-main-actions-desktop.scss';

const useFetchPostMetadata = (postSlug: string) => {
  const actions = useActions();
  const isMetadataLoaded = useSelector(getIsMetadataLoaded);
  React.useEffect(() => {
    if (postSlug && !isMetadataLoaded) {
      actions.fetchPostMetadata(postSlug);
    }
  }, [actions, isMetadataLoaded, postSlug]);

  return { isMetadataLoaded };
};

const PostMainActionsDesktop: React.FC<Props> = ({ post, commentsLink, layoutName }) => {
  const {
    showLikeCount,
    showCommentCount,
    showViewCount,
    showCategoryLabels,
    isMetadataFooterVisible,
  } = useSettingsContext();

  const { getPostClassName } = useIsFeedDesignEnabled();
  const { isPrintEnabled, providers } = usePostPageShareProviders();
  const postId = resolveId(post);
  const isMetadataLoaded = useFetchPostMetadata(post.slug!).isMetadataLoaded;

  const viewCount = useSelector((state) => getViewCount(state, resolveId(post)));
  const totalComments = useSelector((state) => getCommentCount(state, resolveId(post)));

  const hasSocialLinks = providers.length > 0;
  const categoriesCount = post?.categoryIds?.length ?? 0;
  const hasCategories = showCategoryLabels && categoriesCount > 0;
  const hasActionSection = hasSocialLinks || isPrintEnabled || hasCategories;

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.noSeparators]: !hasActionSection && !isMetadataFooterVisible },
        getPostClassName(
          'description-font',
          getFeedColorClassName(layoutName, 'description-color'),
        ),
      )}
      data-hook="post-main-actions-desktop"
    >
      {hasActionSection && [
        <HorizontalSeparator key="separator" />,
        <div key="div" className={classNames(styles.section, styles.mainSection)}>
          <PostIconRow postId={post.id!}>
            {hasSocialLinks && (
              <PostSocialActions
                postSlug={post.slug}
                postId={resolveId(post)}
                enabledProviders={providers}
              />
            )}
            {isPrintEnabled && <PostPrintAction postId={resolveId(post)} />}
          </PostIconRow>
          {hasCategories && <CategoryLabelList post={post} />}
        </div>,
      ]}
      {isMetadataFooterVisible && [
        <HorizontalSeparator key="separator" />,
        <div
          key="div"
          className={classNames(styles.section, styles.bottomSection)}
          data-hook="post-main-actions__stats"
        >
          <div data-hook="post-stats" className={styles.stats}>
            {showViewCount ? (
              <SkeletonRenderer isLoaded={isMetadataLoaded} width={40}>
                <ViewCount count={viewCount} />
              </SkeletonRenderer>
            ) : null}
            {showCommentCount ? (
              <SkeletonRenderer isLoaded={isMetadataLoaded} width={40}>
                {commentsLink ? (
                  <PostLink
                    postLink={commentsLink}
                    postPath={post.slug!}
                    className={classNames(
                      styles.comments,
                      getPostClassName('link-hashtag-hover-color'),
                    )}
                  >
                    <Counter i18nKey="comment-count.label" showZero count={totalComments} />
                  </PostLink>
                ) : (
                  <Counter i18nKey="comment-count.label" showZero count={totalComments} />
                )}
              </SkeletonRenderer>
            ) : null}
          </div>
          {showLikeCount && (
            <SkeletonRenderer isLoaded={isMetadataLoaded} width={40}>
              <LikeButtonWithCount key={postId!} className={styles.likeButton} postId={postId!} />
            </SkeletonRenderer>
          )}
        </div>,
      ]}
    </div>
  );
};

export default PostMainActionsDesktop;
